<script setup lang="ts">
import { Primitive, type PrimitiveProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { type ButtonVariants, buttonVariants } from '.';
import { cn } from '~/libs/util';

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant'];
  size?: ButtonVariants['size'];
  class?: HTMLAttributes['class'];
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
});
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class)"
  >
    <slot />
    <span
      v-if="$slots.icon"
      :class="
        cn(
          'h-fit',
          variant === 'plain' ? '' : 'absolute inset-y-0 right-4 my-auto',
        )
      "
    >
      <slot name="icon"></slot>
    </span>
  </Primitive>
</template>
