import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin, useRuntimeConfig } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  Sentry.init({
    enabled: config.public.sentryEnabled,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    dsn: config.public.sentryDsn,
    environment: config.public.appEnv,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router as any,
        ),
      }),
      new Sentry.Replay(),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: config.public.sentryTracesSampleRate,
    replaysSessionSampleRate: config.public.sentryReplaysSessionSampleRate,
    replaysOnErrorSampleRate: config.public.sentryReplaysOnErrorSampleRate,
  });

  return {
    provide: {
      sentrySetUser: Sentry.setUser,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
