import { default as indexVyIzXx2OBtMeta } from "/codebuild/output/src4270143265/src/src/pages/[companySlug]/faq/[id]/index.vue?macro=true";
import { default as indexkA1ghsQ12vMeta } from "/codebuild/output/src4270143265/src/src/pages/[companySlug]/important-news/index.vue?macro=true";
import { default as indexkaBv4gFaeVMeta } from "/codebuild/output/src4270143265/src/src/pages/[companySlug]/index.vue?macro=true";
import { default as indexpB6BrozvM6Meta } from "/codebuild/output/src4270143265/src/src/pages/[companySlug]/news/[id]/index.vue?macro=true";
import { default as index4mEdKq47saMeta } from "/codebuild/output/src4270143265/src/src/pages/[companySlug]/news/index.vue?macro=true";
import { default as indexhd1sZvPKmDMeta } from "/codebuild/output/src4270143265/src/src/pages/[companySlug]/preview/faq/index.vue?macro=true";
import { default as index16rW5tPDxYMeta } from "/codebuild/output/src4270143265/src/src/pages/[companySlug]/preview/news/index.vue?macro=true";
export default [
  {
    name: "companySlug-faq-id",
    path: "/:companySlug()/faq/:id()",
    component: () => import("/codebuild/output/src4270143265/src/src/pages/[companySlug]/faq/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-important-news",
    path: "/:companySlug()/important-news",
    component: () => import("/codebuild/output/src4270143265/src/src/pages/[companySlug]/important-news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug",
    path: "/:companySlug()",
    component: () => import("/codebuild/output/src4270143265/src/src/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news-id",
    path: "/:companySlug()/news/:id()",
    component: () => import("/codebuild/output/src4270143265/src/src/pages/[companySlug]/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news",
    path: "/:companySlug()/news",
    component: () => import("/codebuild/output/src4270143265/src/src/pages/[companySlug]/news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-faq",
    path: "/:companySlug()/preview/faq",
    component: () => import("/codebuild/output/src4270143265/src/src/pages/[companySlug]/preview/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-news",
    path: "/:companySlug()/preview/news",
    component: () => import("/codebuild/output/src4270143265/src/src/pages/[companySlug]/preview/news/index.vue").then(m => m.default || m)
  }
]