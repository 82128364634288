export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  if (config.public.mock) {
    const { worker } = await import('~/api/mocks/browser');
    worker.start({
      /**
       * MSWのリクエストハンドラーに定義されていないリクエストに対しての警告を無効化する
       * @see https://mswjs.io/docs/api/setup-worker/start#onunhandledrequest
       */
      onUnhandledRequest: 'bypass',
    });
  }
});
