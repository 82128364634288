<script setup lang="ts">
import { Button } from '~/components/ui/button';

defineProps({
  error: Object as any,
});

// 遷移元のページに戻りたい
const handleError = () => clearError();
</script>

<template>
  <div class="container py-6 text-center">
    <h1 class="mb-4 text-2xl font-bold">
      {{
        error.message == 'notAllowedDomain'
          ? 'ご指定のページがみつかりません。'
          : 'エラー'
      }}
    </h1>
    <p class="mb-2">エラーコード: {{ error.statusCode }}</p>
    <p class="mb-4 text-left" v-if="error.message == 'notAllowedDomain'">
      ※ご利用ブラウザがプライベートモードやシークレットモード等の場合は、アクセスができない事がございます。通常モードに切り替えていただき再度アクセスください。
    </p>
    <p class="mb-4" v-else>
      {{ error.message }}
    </p>
    <Button
      v-if="error.message !== 'notAllowedDomain'"
      class="mx-auto w-[200px] bg-primary"
      variant="default"
      @click="handleError"
      >Clear errors</Button
    >
  </div>
</template>
