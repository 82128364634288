<script setup lang="ts">
const { isLoading } = useLoadingState();
</script>

<template>
  <div
    v-if="isLoading"
    className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-white/30"
    aria-label="読み込み中"
  >
    <div
      class="size-10 animate-spin rounded-full border-4 border-primary"
      style="border-top-color: transparent"
    ></div>
  </div>
</template>
