export const useLoadingState = () => {
  /**
   * ローディング状態のステート
   */
  const isLoading = computed(() => loadingKeyList.value.length > 0);

  /**
   * ローディング中のキーのリスト
   */
  const loadingKeyList = useState<string[]>('loadingKeyList', () => []);

  /**
   * ローディング中のキーを追加する
   */
  const addLoadingKey = (key: string) => {
    loadingKeyList.value.push(key);
  };

  /**
   * ローディング中のキーを削除する
   */
  const removeLoadingKey = (key: string) => {
    loadingKeyList.value = loadingKeyList.value.filter(
      (loadingKey) => loadingKey !== key,
    );
  };

  return {
    isLoading,
    addLoadingKey,
    removeLoadingKey,
    loadingKeyList,
  };
};
