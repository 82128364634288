import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

export const buttonVariants = cva(
  'relative flex items-center justify-center whitespace-nowrap rounded text-base font-bold duration-base disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary p-4 py-3 text-white md:hover:bg-primary/70',
        outline:
          'border border-primary bg-white p-4 py-3 text-primary md:hover:opacity-70',
        plain: 'gap-x-1 bg-transparent p-0 text-primary md:hover:opacity-70',
      },
      size: {
        default: 'h-12 w-full',
        square: 'size-12',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
