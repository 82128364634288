<template>
  <NuxtErrorBoundary @error="onError">
    <NuxtLayout>
      <Loading />
      <NuxtPage />
      <VueQueryDevtools />
    </NuxtLayout>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';

const { $sentryCaptureException } = useNuxtApp();

const onError = (error: any) => {
  console.error(error);
  $sentryCaptureException(error);
  showError(error);
};
</script>
